<template>
  <div class="reserve-submit">
    <h3 class="title error-text">Nessuna connessione a Internet!</h3>
  </div>
</template>

<style lang="scss" scoped>
h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;

  &.error-text {
    background-color: rgba(red, 0.6);
  }

  &.success-text {
    background-color: rgba(green, 0.6);
  }
}
</style>

<script>
import { login } from "@/auth/lockerApi.js";
import lockerConfig from "@/lockerConfig";

export default {
  name: "NoConnection",
  data() {
    return {
      homeInterval: null,
    };
  },
  mounted() {
    this.homeInterval = setInterval(async () => {
      let ret = this.checkConnection();
      if (ret) {
        this.$router.push({
          name: "Home",
        });
      }
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.homeInterval);
  },
  methods: {
    async checkConnection() {
      try {
        await login(localStorage.lockerId, localStorage.password);
        return true;
      } catch (error) {
        console.log("Cannot login! No Connection!");
        return false;
      }
    },
  },
};
</script>
